<template class="page-content">
  <ModalLayout :ref="modalRef" :modal-id="`offer-notifications-modal-${matchId}`"
    :loading="isSavableStateLoading"
    @close="modalClosed">
    <template #modal-header>
      <h5 v-if="selectedMatch && selectedMatch.transplant_center" class="modal-title">
        {{ `Notification History - Donor: ${selectedMatch.donor.code} | Match: ${selectedMatch.code}-${selectedMatch.organ.name} | TC: ${selectedMatch.transplant_center.name}` }}
      </h5>
    </template>
    <template #modal-body>
      <div class="row">
        <div class="col-sm-12 mb-2">
          <PortalTable :table-id="`offer-notifications-table-${matchId}`"
            :table-data="alternativeNotifications"
            empty="No notifications found!"
            :total-records="alternativeNotifications.length"
            :loading="isSavableStateLoading"
            selection-mode="single">
            <template #columns>
              <Column field="sent_at" header="Notification Time" style="min-width: 100px;">
                <template #body="slotProps">
                  {{ formatDate(slotProps.data.sent_at) }}
                </template>
              </Column>
              <Column field="type" header="Type" style="min-width: 100px;">
                <template #body="slotProps">
                  {{ labelize(slotProps.data.unet_email.email_type) }}
                </template>
              </Column>
              <Column field="full_name" header="User" style="min-width: 100px;">
                <template #body="slotProps">
                  {{ slotProps.data.user.full_name }}
                </template>
              </Column>
              <Column field="status" header="Status" style="min-width: 100px;">
                <template #body="slotProps">
                  {{ slotProps.data.sending_status == 'successful' ? 'Sent' : labelize(slotProps.data.sending_status) }}
                </template>
              </Column>
              <Column header="Action" style="min-width: 100px;">
                <template #body="slotProps">
                  <ActionToolbar v-if="slotProps.data.sending_status == 'scheduled' && slotProps.data.user.id == user().profileInfo.id"
                    :component-state="currentState" 
                    @primaryClick="dismissNotification(slotProps.data.id)"
                    primary-button-text="Dismiss"
                    primary-style="danger"
                    :secondary-enabled="false"
                    :error-message="formError">
                  </ActionToolbar>
                </template>
              </Column>
            </template>
          </PortalTable>
        </div>
      </div>
    </template>
  </ModalLayout>
</template>

<script lang="js">
import {APIRoute, EP} from "~/src/endpoints";
import beApiClient from "@/src/be-api-client";
import ModalLayout from "@/src/components/shared/ModalLayout.vue";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import {savableStateMixin} from "@/src/mixins/savableStateMixin";
import {modalActionsMixin} from "@/src/mixins/modalActionsMixin";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import {useAppCoreStore} from "@/src/stores/app-core";
import { useMatchStore } from '@/src/stores/match';

export default {
  components: {
    PortalTable,
    ModalLayout,
    Column,
    ActionToolbar
  },
  mixins: [savableStateMixin, modalActionsMixin],
  props: {
    matchId: { required: true, type: String }
  },
  emits: ['close', 'note-updated'],
  data() {
    return {
      alternativeNotifications: [],
      disableSaving: false,
      currentNote: null,
      currentState: null,
      modalRef: `notifications-modal`,
      errorMessage: null
    }
  },
  watch: {
    async matchId() {
      await this.getModalData();
    }
  },
  async mounted() {
    this.selectedMatch = null;
    await this.getModalData();
  },
  setup() {
    const appStore = useAppCoreStore();
    const matchStore = useMatchStore();
    return {
      appStore, matchStore
    }
  },
  methods: {
    user() {
      return this.appStore.currentUser;
    },
    async dismissNotification(id) {
      const endpoint = APIRoute(EP.alternative_notifications.dismiss, { id: id });
        // make request
      try {
        this.setSavableStateLoading();
        await beApiClient.put(endpoint);
        await this.getModalData()
      } catch(e) {
        this.handleFormError(e);
      }

    },
    labelize(snakeCaseString) {
      return snakeCaseString[0].toUpperCase() + snakeCaseString.replace('_', ' ').slice(1)
    },
    formatDate(dateString) {
      let date = new Date(dateString);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
    async getModalData() {
      if(!this.matchId) return;

      try {
        this.setSavableStateLoading();
        const endpoint = APIRoute(EP.matches.alternative_notifications, { match_id: this.matchId });
        // make request
        const response = await beApiClient.get(endpoint);
        // update data
        this.alternativeNotifications = response.data; 
        const matchResponse = await this.matchStore.loadMatch(this.matchId);
        this.selectedMatch = matchResponse;

        // finish
        this.setSavableStateIdle();
      } catch(error) {
        this.handleFormError(error);
      }
    },
    modalClosed() {
      this.setSavableStateBlank();
      this.$emit('close');
    },
  }
}
</script>
