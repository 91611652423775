<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        Operational Reports Dashboard
      </div>
    </div>
  </div>
</template>

<script lang="js">

export default {
  components: {
  }
}
</script>
