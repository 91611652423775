// Setup Axios
import axios from 'axios';

const beApiClient = axios.create({
  baseURL: '/api/v1'
});

beApiClient.interceptors.request.use(function (config) {
  const meta = document.querySelector('meta[name=csrf-token]');
  config.headers['X-CSRF-Token'] =  meta.attributes['content'] ? meta.attributes['content'].value : null;

  return {
    ...config,
    validateStatus: function (status) {
      return (status >= 200 && status < 300 || status === 422);
    }
  };
});

beApiClient.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  // If the response is unauthorized most likely the user has timed out
  // Or signed out. So we will display the message and take back to login.
  if (error.response.status === 401) {
    window.displayModal();
  }
  return Promise.reject(error);
});


export default beApiClient ;
