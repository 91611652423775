<template>
  <div>
    <div class="content-wrap dashboard-widgets">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <CardSection :custom-header="true">
              <template #header>
                <div class="card-header media" style="display: flex; align-items: center">
                  <div class="media-left">
                    <div>
                      <font-awesome-icon :icon="['fas', 'user-group']" />
                    </div>
                  </div>
                  <div class="media-body">
                    <h3 class="card-title d-flex">
                      <span>Recipients</span>
                      <ul class="nav card-nav">
                        <li class="nav-item">
                          <router-link :to="{ name: 'external-dash-available-offers' }" class="router-link-active">
                            Donors
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link :to="{ name: 'external-donor-details', query: {organ: organ, tc: tc} }" class="ml-auto">
                            Donor Details
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link :to="{ name: 'external-donor-list-recipients', query: {organ: organ, tc: tc} }" class="ml-auto">
                            Recipients
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <a href="#" data-toggle="modal" data-target="#txCNotesModal" class="ml-auto" @click="getModalData()">Notes</a>
                        </li>
                      </ul>
                    </h3>
                  </div>
                </div>
              </template>
              <template #body>
                <table class="table w-auto table-borderless table-comparison">
                  <thead>
                    <tr>
                      <td scope="col" class="first-td">
                        &nbsp;
                      </td>
                      <th scope="col">
                        Donor
                      </th>
                      <th scope="col">
                        Recipient
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- comparison details -->
                    <tr>
                      <th scope="row">
                        UNOS/Name
                      </th>
                      <td>
                        {{ donorDetails.code }}
                      </td>
                      <td>
                        {{ recipientDetails.name }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Seq/Rank
                      </th>
                      <td>
                        {{ donorDetails.rankSeq }}
                      </td>
                      <td>
                        {{ recipientDetails.seq + '/' + recipientDetails.rank }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Age
                      </th>
                      <td>
                        {{ donorDetails.age }}
                      </td>
                      <td>
                        {{ recipientDetails.age }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Sex
                      </th>
                      <td>{{ donorDetails.gender }}</td>
                      <td>{{ recipientDetails.gender }}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Height
                      </th>
                      <td>
                        {{ donorDetails.height }}
                      </td>
                      <td>
                        {{ recipientDetails.height }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Weight
                      </th>
                      <td>
                        {{ donorDetails.weight }}
                      </td>
                      <td>
                        {{ recipientDetails.weight }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Blood Type
                      </th>
                      <td>
                        {{ donorDetails.bloodType }}
                      </td>
                      <td>
                        {{ recipientDetails.bloodType }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        BMI
                      </th>
                      <td>
                        {{ donorDetails.bmi }}
                      </td>
                      <td>
                        {{ recipientDetails.bmi }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Previous Transplants
                      </th>
                      <td>
                        {{ donorDetails.previousTransplants }}
                      </td>
                      <td>
                        {{ recipientDetails.previousTransplants }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Other Organs
                      </th>
                      <td>
                        {{ donorDetails.otherOrgans }}
                      </td>
                      <td>
                        {{ recipientDetails.otherOrgans }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Unacceptable Antigens
                      </th>
                      <td>
                        {{ donorDetails.hlaComparisonOrCrossMatchNeeded }}
                      </td>
                      <td>
                        {{ recipientDetails.hlaComparison }}
                      </td>
                    </tr>
                    <tr v-if="isCPRAVisible()">
                      <th scope="row">
                        cPRA
                      </th>
                      <td>
                        {{ donorDetails.cPRA }}
                      </td>
                      <td>
                        {{ recipientDetails.cPRA }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Crossmatch Needed
                      </th>
                      <td>
                        {{ donorDetails.hlaComparisonOrCrossMatchNeeded }}
                      </td>
                      <td>
                        {{ recipientDetails.crossMatchNeeded }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        CIT
                      </th>
                      <td>
                        {{ donorDetails.cit }}
                      </td>
                      <td>
                        {{ recipientDetails.cit }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        OR Time
                      </th>
                      <td>
                        {{ donorDetails.orTime }}
                      </td>
                      <td>
                        {{ recipientDetails.orTime }}
                      </td>
                    </tr>
                    <tr v-if="organ == 'Liver'">
                      <th scope="row">
                        MELD
                      </th>
                      <td>
                        {{ donorDetails.meld }}
                      </td>
                      <td>
                        {{ recipientDetails.meld }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Dialysis Time
                      </th>
                      <td>
                        {{ donorDetails.dialysisTime }}
                      </td>
                      <td>
                        {{ recipientDetails.dialysisTime }}
                      </td>
                    </tr>

                    <!-- spacer -->
                    <tr>
                      <td colspan="2" />
                    </tr>

                    <!-- recipient specific details -->
                    <tr>
                      <th scope="row" colspan="2">
                        Recipient Specific Details
                      </th>
                    </tr>
                    <tr>
                      <th scope="row">
                        Mismatch
                      </th>
                      <td>{{ recipientDetails.mismatch }}</td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </CardSection>
          </div>
        </div>
      </div>
    </div>
    <NotesModal :match-id="selectedData.match.id" />
  </div>
</template>

<script lang="js">
import beApiClient from "@/src/be-api-client";
import { APIRoute, EP } from "~/src/endpoints";
import { formatDateTime, formatTime, formatDate, convertCmToInch, convertKgToLbs } from "@/src/date-helpers";
import NotesModal from "@/src/components/external-clinical-area/TxCNotes.vue";
import CardSection from "@/src/components/shared/CardSection.vue";
import { useAppCoreStore } from "@/src/stores/app-core";

export default {
  components: {
    CardSection,
    NotesModal
  },
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore,
    };
  },
  data() {
    return {
      recipient: [],
      loading: false,
      majorError: null,
      selectedData: '',
      donor: '',
      match: '',
      matchId: '',
      organ: '',
      seq: '',
      rank: '',
      recipientId: '',
      tc: '',
      donorDetails: {},
      recipientDetails: {}
    }
  },
  mounted() {
    this.appStore.setAppBreadcrumbs([{text: 'Loading...'}]);
    this.donor = this.$route.params.donor_id;
    this.matchId = this.$route.params.id;
    this.recipientId = this.$route.params.recipient_id;
    this.organ = this.$route.query.organ;
    this.seq = this.$route.query.seq;
    this.rank = this.$route.query.rank;
    this.tc = this.$route.query.tc;

    this.getmatch();
  },
  methods: {
    isCPRAVisible() {
      if(this.organ == 'Kidney' || this.organ == 'Kidney-Pancreas') return true; 
      return false;
    },
    getmatch() {
      this.loading = true;
      beApiClient.get(APIRoute(EP.matches.show, { id: this.matchId}))
      .then((responses) => {
        this.match = responses.data;
        this.getDonorDetails();
        this.getRecipientInfo();
        
      }).catch(e => {
        this.majorError = e;
      }).finally(() => { this.loading = false });
    },
    getRecipientInfo() {
      beApiClient.get(APIRoute(EP.recipients.show, { id: this.recipientId })).then((response) => {
        this.recipientInfo(response.data);
      }).catch(e => {
        this.majorError = e;
      }).finally(() => {
        this.loading = false;
        this.setBreadcrumbs();
      });
    },
    getDonorDetails() {
      this.loading = true;
      beApiClient.get(APIRoute(EP.donors.show, { id: this.donor })).then((response) => {
        this.donorInfo(response.data.donor);
      }).catch(e => {
        this.majorError = e;
      }).finally(() => {
        this.loading = false;
        this.setBreadcrumbs();
      });
    },
    setBreadcrumbs() {
      this.appStore.setAppBreadcrumbs([
        {
          to: { name: 'external-dash-available-offers' },
          text: 'Donors',
        },
        {
          to: { name: 'external-donor-details', query: { organ: this.organ, tc: this.tc } },
          text: `${this.match?.donor?.code} - ${this.match?.code} - ${this.organ}`,
        },
        {
          to: { name: 'external-donor-list-recipients', query: { organ: this.organ, tc: this.tc } },
          text: 'Recipients',
        },
        {
          text: `${this.recipientDetails?.name} | S${this.displayRecipientSequence(this.recipientDetails)} | R${this.displayRecipientRank(this.recipientDetails)}`,
        },
      ]);
    },
    displayRecipientSequence(recipientDetails) {
      const seq = recipientDetails?.seq;
      if (seq == null) return ' N/A';

      return `${seq}`;
    },
    displayRecipientRank(recipientDetails) {
      const rank = recipientDetails?.rank;
      if (rank == null) return ' N/A';

      return `${rank}`;
    },
    recipientInfo(details) {
      this.recipientDetails = {
        name: details.recipient_first_name.slice(0,1) + '. ' + details.recipient_last_name,
        rank: this.rank,
        seq: this.seq,
        age: details.age,
        gender: details.gender || 'N/A',
        height: details.height ? (details.height + 'cm / ' + convertCmToInch(details.height) + 'in' ) : '',
        weight: details.weight ? (details.weight + 'kg / ' + convertKgToLbs(details.weight) + 'lbs' ) : '',
        bmi: details.bmi,
        bloodType: details.blood_type,
        previousTransplants: details.prev_kidney_transplants + details.prev_liver_transplants || 'N/A',
        otherOrgans: details.other_organs || 'N/A',
        hlaComparison: details.ua_value ? (details.ua_value == 'Y' ? 'Yes' : 'No') : 'N/A',
        cPRA: details.cpra || 'N/A',
        crossMatchNeeded: details.x_value ? (details.x_value == 'Y' ? 'Yes' : 'No') : 'N/A',
        orTime: 'N/A',
        meld: this.organ == "Liver" && details.score ? details.score : 'N/A',
        dialysisTime: details.dialysis_time || 'N/A',
        cit: 'N/A',
        mismatch: (details.mismatch_a ? details.mismatch_a : '0') + ', '+ (details.mismatch_b ? details.mismatch_b : '0') + ', ' + (details.mismatch_dr ? details.mismatch_dr : '0')
      }
    },
    donorInfo(details) {
      this.donorDetails = {
        code: details?.code,
        rankSeq: 'N/A',
        age: details.age,
        gender: details.birthsex ? details.birthsex : details.gender,
        height: details.height ? (details.height + 'cm / ' + convertCmToInch(details.height) + 'in' ) : '',
        weight: details.weight ? (details.weight + 'kg / ' + convertKgToLbs(details.weight) + 'lbs' ) : '',
        bmi: details.bmi,
        bloodType: details.blood_type,
        previousTransplants: 'N/A',
        otherOrgans: 'N/A',
        hlaComparisonOrCrossMatchNeeded: 'N/A',
        cPRA: 'N/A',
        orTime: details?.or_datetime ? (formatDateTime(details.or_datetime) + ' ' + details?.provider_informations[0].time_zone) : 'N/A',
        meld: 'N/A',
        dialysisTime: 'N/A',
        cit: 'N/A'
      }
    },
    getModalData() {
      this.selectedData = {
        donor: this.donor,
        match: this.match,
        organ: this.organ,
        tc: this.tc,
        notes: this.notes
      }
    },
  }
}
</script>