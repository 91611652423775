<template>
  <div class="content-wrap dashboard-widgets">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <CardSection :custom-header="true">
            <template #header>
              <div class="card-header media" style="display: flex; align-items: center">
                <div class="media-left">
                  <div>
                    <font-awesome-icon :icon="['fas', 'user-group']" />
                  </div>
                </div>
                <div class="media-body">
                  <h3 class="card-title d-flex">
                    <template v-if="currentStep === STEPS.SEARCH">
                      Search Offer
                    </template>
                    <template v-if="currentStep === STEPS.EDIT">
                      Edit Offer
                    </template>
                  </h3>
                </div>
              </div>
            </template>
            <template #body>
              <SearchOfferForm v-if="currentStep === STEPS.SEARCH" :transplant-centers="transplantCenters" @search="searchOffer($event)" />
              <OfferForm v-if="currentStep === STEPS.EDIT" :offer-info="selectedOffer" @close="close" />
              <ConfirmDialogue ref="confirmDialogue"
                @confirm="editOffer"
                @cancel="showSearchForm" />
            </template>
          </CardSection>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">

import PageTop from '~/src/components/shared/PageTop.vue';
import beApiClient from "@/src/be-api-client";
import {APIRoute, EP} from "~/src/endpoints";
import ConfirmDialogue from "@/src/components/buckeye-coordinator-area/manual_offers/ConfirmDialogue.vue";

import OfferForm from "@/src/components/buckeye-coordinator-area/manual_offers/OfferForm.vue";
import SearchOfferForm from "~/src/components/buckeye-coordinator-area/manual_offers/SearchOfferForm.vue";
import CardSection from "@/src/components/shared/CardSection.vue";
import {useAppCoreStore} from "@/src/stores/app-core";

const STEPS = {
  SEARCH: 'search',
  EDIT: 'edit'
}
export default {
  components: {
    SearchOfferForm,
    OfferForm,
    ConfirmDialogue,
    CardSection
  },
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore
    }
  },
  data() {
    return {
      loading: false,
      transplantCenters: [],
      selectedOffer: {
        donor: '',
        match: '',
        tc: '',
      },
      STEPS,
      currentStep: STEPS.SEARCH
    }
  },
   mounted() {
     this.appStore.setAppBreadcrumbs([
       {
         to: { name: 'internal-dashboards-root' },
         text: 'Dashboards'
       },
       {
         to: { name: 'internal-dashboard-manual' },
         text: 'Offers Dashboard'
       },
       {
         text: 'Manage Offer'
       }
     ]);
     if(this.$route.query && this.$route.query.donor_code &&
        this.$route.query.match_code && this.$route.query.tc) {
       this.selectedOffer.donor_code = this.$route.query.donor_code;
       this.selectedOffer.match_code = this.$route.query.match_code;
       this.selectedOffer.tc = this.$route.query.tc;
       this.currentStep = this.STEPS.EDIT;
     }
     this.getBaseAccessData();
  },
  methods: {
    getBaseAccessData() {
      this.loading = true;
      beApiClient.get(APIRoute(EP.transplant_centers.index)).then((response) => {
        this.transplantCenters = response.data;
      }).catch(e => {
        this.errorMessage = e;
      }).finally(() => {
        this.loading = false
      });
    },
    /**
     * Search the offer with the payload from the SearchOfferForm.
     * Payload structure:
     *    {
     *      donor_code: 'XXXX###'
     *      match_code: '#########'
     *      tc: 'XXXX'
     *    }
     * @param {Object} payload
     * @param {String} payload.donor_code
     * @param {String} payload.match_code
     * @param {String} payload.tc
     */
    searchOffer(payload) {
      this.selectedOffer = payload;
      beApiClient.get(APIRoute(EP.matches.validate_match), { params: payload }).then(() => {
        this.editOffer(payload);
      }).catch(error => {
        // If it's a 404 that means it's a new offer
        if(error.response.status === 404) {
          this.editOffer(payload);
        }
        // If it's a 400 then we have a mismatch
        if(error.response.status === 400) {
          this.confirmMismatch(error.response.data.donor_mismatch);
        }
      });
    },
    confirmMismatch(donor_mismatch) {
      this.confirmationMessage = `Match ${donor_mismatch.match} already exists with Donor(s) ${donor_mismatch.donor}.
      \nSelect 'Cancel' to re-do the search or 'Add New Offer' to proceed with new offer entry.`;

      this.$refs.confirmDialogue.show({
        title: 'Warning!',
        message: this.confirmationMessage,
        confirmButtonText: 'Add new offer',
      });
    },
    showSearchForm() {
      // this.$router.push({ name: 'manage-offer', query: this.selectedOffer });
      this.currentStep = this.STEPS.SEARCH;
    },
    /**
     * Setup the editing form.
     */
    editOffer() {
      this.$router.push({ name: 'manage-offer', query: this.selectedOffer });
      this.currentStep = this.STEPS.EDIT;
    },
    close() {
      this.$router.push({
        name: "internal-dashboard-manual"
      });
    }
  }

}
</script>
