<template>
  <CardSection header-class="title-bar">
    <template #header>
      Admin Operational Reports
    </template>
    <template #body>
      <div class="row">
        <ReportSetView report-set-base-url="/report_sets/admin_operational" />
      </div>
    </template>
  </CardSection>
</template>

<script lang="js">
  import ReportSetView from "@/src/components/rundown/ReportSetView.vue";
  import CardSection from "@/src/components/shared/CardSection.vue";

  export default {
    components: {
      CardSection,
      ReportSetView
    }
  }
</script>