<template>
  <div>
    <DashboardWidgetLayout widget-id="external-analytics-txc-offers-full"
      widget-title="Transplant Center Offers"
      :widget-icon="['fas', 'chart-simple']">
      <template #filters>
        <VeeForm @submit="searchFilters">
          <div v-if="!loading" class="row">
            <!-- Transplant Center Filter -->
            <MultiSelectInput v-model="filters.transplantCenterFilter"
              name="analytics-txc-offers-filter-txc"
              label="Transplant Center"
              label-key="full_name"
              value-key="id"
              col-style="form-group col-md-3 col-sm-6"
              :options="transplantCenterOptions"
              placeholder="Select..."
              :multiple="true"
              rules="required"
              @change="onChangeTransplantCenter" />
            <!-- Organ Filter -->
            <MultiSelectInput v-model="filters.organFilter"
              name="analytics-txc-offers-filter-organ"
              label="Organ"
              label-key="full_name"
              value-key="id"
              col-style="form-group col-md-3 col-sm-6"
              :options="organOptions"
              placeholder="All"
              :multiple="true"
              @change="onChangeOrganFilter" />
            <!-- Month and Year filter -->
            <DateSelect v-model="filters.monthYearFilter"
              name="analytics-txc-offers-filter-date"
              label="Month and Year"
              placeholder="Select..."
              col-style="form-group col-md-3 col-sm-6"
              rules="required" />
          </div>
          <div class="sub-divider" />
          <div class="save-toolbar-column">
            <button type="submit" data-target="#idAnalyticsTxCOffers" class="btn btn-success">
              Search
            </button>
            <button type="button" data-target="#idAnalyticsTxCOffers"
              class="btn btn-outline-secondary ml-3" @click="resetFilters">
              Reset Filters
            </button>
          </div>
        </VeeForm>
      </template>
      <template #content>
        <template v-if="error">
          <div class="fade-list-item notification notification-error notification-inline button-area">
            <p>
              <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
              {{ error }}
            </p>
          </div>
        </template>
        <div class="row">
          <TotalOffers ref="total-offers" :organ_groups="organGroups" :transplant_centers="transplantCenterOptions" />

          <TotalTransplantedOrgans ref="total-transplanted-organs" :organ_groups="organGroups" :transplant_centers="transplantCenterOptions" />

          <PercentAcceptedAfterDecline ref="percent-accepted-after-decline" :organ_groups="organGroups" :transplant_centers="transplantCenterOptions" />

          <PercentAutoRuleOutsForFullyDeclinedOffers ref="percent-auto-rule-outs-for-fully-declined-offers" :organ_groups="organGroups" :transplant_centers="transplantCenterOptions" />
        </div>
        <div class="row">
          <ChartOrganOffersReceived ref="chart-organ-offers-received" :organ_groups="organGroups" :transplant_centers="transplantCenterOptions" />

          <ChartTransplantedOrgans ref="chart-transplanted-organs" :organ_groups="organGroups" :transplant_centers="transplantCenterOptions" />

          <ChartNonRecovery ref="chart-non-recovery" :organ_groups="organGroups" :transplant_centers="transplantCenterOptions" />

          <ChartFinalDispositions ref="chart-final-dispositions" :organ_groups="organGroups" :transplant_centers="transplantCenterOptions" />
        </div>
      </template>
    </DashboardWidgetLayout>
  </div>
</template>

<script lang="js">
import beApiClient from "@/src/be-api-client";
import { APIRoute, EP } from "~/src/endpoints";
import DashboardWidgetLayout from "~/src/components/shared/DashboardWidgetLayout.vue";
import { useAppCoreStore } from "@/src/stores/app-core";
import TotalOffers from "~/src/components/external-clinical-area/analytics/TotalOffers.vue";
import TotalTransplantedOrgans from "~/src/components/external-clinical-area/analytics/TotalTransplantedOrgans.vue";
import PercentAcceptedAfterDecline from "~/src/components/external-clinical-area/analytics/PercentAcceptedAfterDecline.vue";
import PercentAutoRuleOutsForFullyDeclinedOffers from "~/src/components/external-clinical-area/analytics/PercentAutoRuleOutsForFullyDeclinedOffers.vue";
import ChartOrganOffersReceived from "~/src/components/external-clinical-area/analytics/ChartOrganOffersReceived.vue";
import ChartTransplantedOrgans from "~/src/components/external-clinical-area/analytics/ChartTransplantedOrgans.vue";
import ChartNonRecovery from "~/src/components/external-clinical-area/analytics/ChartNonRecovery.vue";
import ChartFinalDispositions from "~/src/components/external-clinical-area/analytics/ChartFinalDispositions.vue";
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";
import DateSelect from "@/src/components/shared/FormComponents/DateSelect.vue";
import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate';
import { pageMixin } from "@/src/mixins/pageMixin";

const WIDGET_REFS = [
  'total-offers',
  'total-transplanted-organs',
  'percent-accepted-after-decline',
  'percent-auto-rule-outs-for-fully-declined-offers',
  'chart-organ-offers-received',
  'chart-transplanted-organs',
  'chart-non-recovery',
  'chart-final-dispositions',
];

export default {
  components: {
    DashboardWidgetLayout,
    TotalOffers,
    TotalTransplantedOrgans,
    PercentAcceptedAfterDecline,
    PercentAutoRuleOutsForFullyDeclinedOffers,
    ChartOrganOffersReceived,
    ChartTransplantedOrgans,
    ChartNonRecovery,
    ChartFinalDispositions,
    MultiSelectInput,
    DateSelect,
    VeeForm
  },
  mixins: [ pageMixin ],
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore,
    };
  },
  data() {
    return {
      error: '',
      loading: false,
      organGroups: [],
      filters: {
        transplantCenterFilter: [],
        organFilter: [],
        monthYearFilter: null,
      },
    };
  },
  computed: {
    transplantCentersWithAccess() {
      const currentUser = this.appStore?.currentUser;
      if (!currentUser) return [];

      const transplantCenters = currentUser?.profileInfo?.transplant_centers_with_access || [];
      return transplantCenters;
    },
    transplantCenterOptions() {
      const options = this.transplantCentersWithAccess.map((item) => {
        return {
          id: item.id,
          code: item.code,
          name: item.name,
          full_name: `${item.code } - ${ item.name }` 
        };
      });

      // sort options by code
      const sorted = this.sortArray(options, 'code');

      return sorted;
    },
    organOptions() {
      const options = this.organGroups.map((item) => {
        return {
          id: item.id,
          organ_code: item.organ_code,
          name: item.name,
          full_name: `${item.organ_code } - ${ item.name }` 
        };
      });

      // sort options by organ_code
      const sorted = this.sortArray(options, 'organ_code');

      return sorted;
    },
  },  
  mounted() {
    this.appStore.setAppBreadcrumbs([
      {
        to: { name: 'external-dashboards-root' },
        text: 'Dashboards',
      },
      {
        text: 'Analytics Dashboard',
      },
    ]);
    this.getBaseData();
  },
  methods: {
    getBaseData() {
      this.loading = true;
      this.error = '';
      beApiClient.get(APIRoute(EP.admin.organ_groups.index)).then((response) => {
        this.organGroups = response.data || [];
        // We don't want to show Heart/Lung as per ABT-3030
        this.organGroups = this.organGroups.filter(item => item.organ_code !== 'HL');
        this.setDefaultFilters();
        this.loadChartData();
      }).catch(e => {
        this.error = e;
      }).finally(() => {
        this.loading = false
      });
    },
    setDefaultFilters() {
      // reset to all available
      this.filters.transplantCenterFilter = this.transplantCenterOptions.map((item) => { return item.id; });
      this.filters.organFilter = this.organOptions.map((item) => { return item.id; });
      // reset date to one month behind current date
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 1);
      this.filters.monthYearFilter = { month: currentDate.getMonth(), year: currentDate.getFullYear() };
    },
    resetFilters() {
      this.setDefaultFilters();
      this.loadChartData();
    },
    searchFilters() {
      this.loadChartData();
    },
    loadChartData() {
      const filterParameters = this.buildFilterParameters();
      WIDGET_REFS.forEach((widgetRef) => {
        const widgetComponent = this.$refs[widgetRef];
        if (!widgetComponent) console.warn('WARNING: cannot find widget to reload', { widgetRef });
        widgetComponent.loadChartData(filterParameters);
      });
    },
    buildFilterParameters() {
      const filters = this.filters || {};

      const transplantCenterFilter = filters.transplantCenterFilter || [];
      const transplant_centers = transplantCenterFilter ? transplantCenterFilter.join(',') : '';

      const organFilter = filters.organFilter || [];
      const organ_groups = organFilter ? organFilter.join(',') : '';

      const monthYearFilter = filters.monthYearFilter || {};
      const month = monthYearFilter.month != null ? (parseInt(monthYearFilter.month) + 1) : '';
      const year = monthYearFilter.year != null ? (parseInt(monthYearFilter.year)) : '';

      const result = {
        transplant_centers,
        organ_groups,
        month,
        year,
      };
      return result;
    },
    onChangeOrganFilter(value) {
      if (value.length == 0) {
        this.filters.organFilter = null;
      } else {
        // if multiple send array other convert to string
        this.filters.organFilter = value
      }
    },
    onChangeTransplantCenter(value) {
      if (value.length == 0) {
        this.filters.transplantCenterFilter = null;
      } else {
        // if multiple send array other convert to string
        this.filters.transplantCenterFilter = value
      }
    },
  }
}
</script>

<style>
  .multiselect__tag {
    background: #f2f2f2; /* $highlight-grey */
    color: #2e2e2e; /* $text-dark */
  }
  .multiselect__tag-icon::after {
    color: #78909c; /* $text-grey */
  }
  .multiselect__tag-icon::after:hover {
    color: #157abf; /* $nav-color */
  }
  .multiselect__option--highlight {
    background: #157abf; /* $nav-color */
    color: #2e2e2e; /* $text-dark */
  }
  .multiselect__option--selected.multiselect__option--highlight {
    background: #f2f2f2; /* $highlight-grey */
    color: #2e2e2e; /* $text-dark */
  }
  .multiselect__spinner::before, .multiselect__spinner::after {
    border-color: #2e2e2e transparent transparent; /* $text-dark transparent transparent */
  }
</style>
