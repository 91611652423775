<template>
  <ModalLayout :ref="modalRef" @close="closeModal()">
    <template #modal-header>
      <h5 class="modal-title">
        Customize Columns
      </h5>
    </template>
    <template #modal-body>
      <div class="row">
        <!-- Iterate through each group of options/checkboxes -->
        <div v-for="quintuple in groupsOf(options, 6)" :key="quintuple.key"
          class="col-md-4">
          <ul class="nav flex-column">
            <li v-for="option in quintuple.options" :key="option.code" class="mb-2">
              <div class="checklist border-0 form-check">
                <input :id="`${configId}-${option[fieldKey]}`"
                  type="checkbox"
                  class="form-check-input"
                  :checked="modelValue.includes(option[fieldKey])"
                  @change="onCheckboxChange(option[fieldKey], $event)">
                <label class="form-check-label selected"
                  :for="`${configId}-${option[fieldKey]}`">
                  <span>
                    <slot name="label">
                      {{ option.label }}
                    </slot>
                  </span>
                </label>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <ActionToolbar :component-state="currentState" 
        :primary-button-text="Save"
        :primary-disabled="false"
        success-message="Saved Successfully!" 
        :error-message="error" 
        :secondary-enabled="true" 
        secondary-button-text="Close"
        @primary-click="saveColumns"
        @secondary-click="closeModal" />
    </template>
  </ModalLayout>
</template>

<script lang="js">
import ModalLayout from "@/src/components/shared/ModalLayout.vue";
import {modalActionsMixin} from "@/src/mixins/modalActionsMixin";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { useAppCoreStore } from "@/src/stores/app-core";
import { useUserStore } from '@/src/stores/user';
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";

export default {
  components: {
    ModalLayout,
    ActionToolbar
  },
  mixins: [ modalActionsMixin, savableStateMixin ],
  props: {
    // Required props
    modelValue: { required: true, type: Array },
    columnKey: { required: true, type: String },
    optionDefaults: { required: true, type: String },
    configId: { required: true, type: String },
    options: { required: true, type: Array },

    // Optional props
    labelKey: { default: 'label', type: String },
    fieldKey: { default: 'field', type: String },
    hiddenOptions: { default: () => [], type: Array },
  },
  emits: ['close', 'changeSelected'],
  setup() {
    const appStore = useAppCoreStore();
    const userStore = useUserStore();
    return {
      appStore, userStore
    }
  },
  data() {
    return {
      preview: null,
      modalRef: 'columnConfigModal',
      errorMessage: null,
    }
  },
  methods: {
    groupsOf(array, splitBy = 3) {
      if (!array) {
        return [];
      }
      // Store options in groups of (splitBy)
      let index = 0;
      let arrayLength = array.length;
      let tempArray = [];
    
      for (index = 0; index < arrayLength; index += splitBy) {
        let newChunk = array.slice(index, index + splitBy);
        // push new chunk in the new array
        tempArray.push(newChunk);
      }

      // attach key to each group
      let key;
      let codes;
      const results = tempArray.map((group) => {
        codes = group.map((option) => {
          const field = option[this.fieldKey];
          return field;
        });
        key = codes.join('-');
        return {
          key: key,
          options: group,
        };
      });

      return results
    },
    onCheckboxChange(key, event) {
      const ticked = event.target.checked;
      const newValue = Array.from(this.modelValue);
      if (ticked) {
        // Add ticked field to overall model value if not already present
        const index = newValue.indexOf(key);
        if (index == -1) {
          newValue.push(key);
        }
      } else {
        // Remove unticked checkbox field from overall model value if present
        const index = newValue.indexOf(key);
        if (index > -1) {
          newValue.splice(index, 1);
        }
      }
      // Sanitize value and report the change
      const result = [...new Set(newValue)];
      this.$emit('changeSelected', result);
    },
    extractColumnSettings() {
      const newPreferences = this.appStore.currentUser.getPreferences(); // get preferences
      newPreferences[this.columnKey] = this.modelValue; // apply value to this section of preferences
      return newPreferences; // return altered preferences
    },
    async saveColumns() {
      // this.$refs.messageComponent.reset();
      try {
        this.setSavableStateProcessing();
        const newState = this.extractColumnSettings();
        await this.appStore.updatePreferences(newState);
        this.setSavableStateSuccess();
      } catch(error) {
        console.log(error);
        this.handleFormError(error);
      }
    }
  }
}
</script>
