<template>
  <div>
    <div class="content-wrap dashboard-widgets">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <CardSection :custom-header="true">
              <template #header>
                <div class="card-header media" style="display: flex; align-items: center">
                  <div class="media-left">
                    <div class="">
                      <font-awesome-icon :icon="['fas', 'user']" />
                    </div>
                  </div>
                  <div class="media-body">
                    <div class="card-title d-flex">
                      <span>Donor Details</span>
                      <ul class="nav card-nav">
                        <li class="nav-item">
                          <router-link :to="{ name: 'external-dash-available-offers' }" class="router-link-active">
                            Donors
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link :to="{ name: 'external-donor-list-recipients', query: {organ: organ, tc: tc} }" class="ml-auto">
                            Recipients
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <a href="#" data-toggle="modal" data-target="#txCNotesModal" class="ml-auto" @click="getModalData()">Notes</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>    
              </template>
              <template v-if="!loading && donorDetails" #body>
                <table class="table table-borderless w-auto table-responsive table-details">
                  <tbody>
                    <tr>
                      <th scope="row">
                        Organ
                      </th>
                      <td>
                        {{ organ }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Location
                      </th>
                      <td>
                        {{ donorDetails.location }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        (Alternative) Recovery facility
                      </th>
                      <td>
                        {{ donorDetails.alternative_recovery_facility }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Type of Donor
                      </th>
                      <td>
                        {{ donorDetails.donorType }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Blood Type
                      </th>
                      <td>
                        {{ donorDetails.bloodType }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Age
                      </th>
                      <td>
                        {{ donorDetails.age }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Sex
                      </th>
                      <td>
                        {{ donorDetails.gender }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Height
                      </th>
                      <td>
                        {{ donorDetails.height }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Weight
                      </th>
                      <td>
                        {{ donorDetails.weight }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        BMI
                      </th>
                      <td>
                        {{ donorDetails.bmi }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        KDPI
                      </th>
                      <td>
                        {{ donorDetails.kdpi }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Admit Date
                      </th>
                      <td>
                        {{ donorDetails.admitDate }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Pronounced
                      </th>
                      <td>
                        {{ donorDetails.pronounced }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        OR Time
                      </th>
                      <td>
                        {{ donorDetails.orTime }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Clamp Time
                      </th>
                      <td>
                        {{ donorDetails.clampTime }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        COD
                      </th>
                      <td>
                        {{ donorDetails.cod }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Downtime/CPR
                      </th>
                      <td>
                        {{ donorDetails.downtimeCpr }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        PMHx
                      </th>
                      <td>
                        <ul v-for="(phmx, index) in donorDetails.pmhx.normal" :key="index" class="list-unstyled">
                          <li>- {{ phmx.name }}: {{ phmx.info }}</li>
                        </ul>
                        <ul class="list-unstyled">
                          <li>
                            Known Risk: {{ donorDetails.pmhx.known_risk }} {{ donorDetails.pmhx.criteria_thirty_days }}
                          </li>
                        </ul>
                        <ul v-for="(phmx, index) in donorDetails.pmhx.blood_borne" :key="index" class="list-unstyled">
                          <li>- {{ phmx.name }}: {{ phmx.info }} </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        PMHx Summary
                      </th>
                      <td>
                        <button type="button" data-toggle="collapse" data-target="#PMHxSummary1" 
                          aria-expanded="false" aria-controls="PMHxSummary1" class="btn btn-link" 
                          @click="toggleCollapseText">
                          {{ collapseText }}
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td class="py-0">
                        <span class="sr-only">PMHx Summary</span>
                      </td>
                      <td class="py-0">
                        <div id="PMHxSummary1" class="collapse py-2">
                          {{ donorDetails.phmxSummary }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Donor Highlights
                      </th>
                      <td>
                        <button type="button" data-toggle="collapse" data-target="#DonorHighlights" 
                          aria-expanded="false" aria-controls="DonorHighlights" class="btn btn-link" 
                          @click="toggleCollapseText">
                          {{ collapseText }}
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td class="py-0">
                        <span class="sr-only">Donor Highlights</span>
                      </td>
                      <td class="py-0">
                        <div id="DonorHighlights" class="collapse py-2">
                          <span style="white-space: pre-wrap;">{{ donorDetails.donorHighlights }} </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Admit Course
                      </th>
                      <td>
                        <button type="button" data-toggle="collapse" data-target="#admitCourse1" 
                          aria-expanded="false" aria-controls="admitCourse1" class="btn btn-link" 
                          @click="toggleCollapseText">
                          {{ collapseText }}
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td class="py-0">
                        <span class="sr-only">Admit Course</span>
                      </td>
                      <td class="py-0">
                        <div id="admitCourse1" class="collapse py-2">
                          {{ donorDetails.admitCourse }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Known Risks
                      </th>
                      <td>
                        {{ donorDetails.knownRisk }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Positive Seros
                      </th>
                      <td>
                        <button data-toggle="collapse" aria-expanded="false"
                          class="btn btn-link collapsed" aria-controls="positiveSeros1" data-target="#positiveSeros1" 
                          @click="toggleCollapseText">
                          {{ collapseText }}
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td class="py-0">
                        <span class="sr-only">Positive Serology table</span>
                      </td>
                      <td class="py-0">
                        <div v-for="(sero, index) in donorDetails.positiveSeros" id="positiveSeros1" :key="index" class="collapse py-2">
                          <ul class="list-unstyled">
                            <li v-if="sero.result == 'Positive'">
                              {{ sero.infectious_disease.code }}: Positive
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Tests and Labs
                      </th>
                      <td>
                        <button type="button" data-toggle="collapse" data-target="#tests1" 
                          aria-expanded="false" aria-controls="tests1" class="btn btn-link" 
                          @click="toggleCollapseText">
                          {{ collapseText }}
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td class="py-0">
                        <span class="sr-only">Tests and Labs</span>
                      </td>
                      <td class="py-0">
                        <div id="tests1" class="collapse py-2">
                          <div id="accordion">
                            <div class="card">
                              <div id="testAndDiagnosis" class="card-header">
                                <h5 class="mb-0">
                                  <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTestAndDiagnosis" aria-expanded="false" aria-controls="collapseTestAndDiagnosis">
                                    Tests & Diagnoses
                                  </button>
                                </h5>
                              </div>

                              <div id="collapseTestAndDiagnosis" class="collapse" aria-labelledby="testAndDiagnosis" data-parent="#accordion">
                                <div class="card-body">
                                  <table class="table table-bordered table-hover table-responsive table-recipient-list">
                                    <thead style="background:#f2f2f2">
                                      <tr>
                                        <th>Test Type</th>
                                        <th>Test Date</th>
                                      </tr>
                                    </thead>
                                    <tr v-for="(test, index) in donorDetails.testResults" :key="index">
                                      <td>
                                        <ul class="list-unstyled">
                                          <span class="show-table-header-on-mobile">Test Type</span>
                                          <b>{{ test.test_type == 'Other' ? test.test_type + ', specify:' + test.other_test_type : test.test_type }}</b>
                                          <li>{{ test.comments }}</li>
                                        </ul>
                                      </td>
                                      <td>
                                        <span class="show-table-header-on-mobile">Test Date</span>
                                        {{ formatDateTime(test.datetime) }}
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div v-if="displayLab" class="card">
                              <div id="lab" class="card-header">
                                <h5 class="mb-0">
                                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseLab" aria-expanded="false" aria-controls="collapseLab">
                                    LABS
                                  </button>
                                </h5>
                              </div>
                              <div id="collapseLab" class="collapse" aria-labelledby="lab" data-parent="#accordion">
                                <div class="card-body">
                                  <div id="tests1" class="collapse py-2">
                                    <button class="btn btn-link float-right" @click="canEnableDateTime">
                                      Show DateTime
                                    </button>
                                    <table class="table table-bordered table-hover table-recipient-list">
                                      <thead style="background:#f2f2f2">
                                        <tr>
                                          <th />
                                          <th>Most Recent</th>
                                          <th>Peak</th>
                                          <th>Admit</th>
                                        </tr>
                                      </thead>
                                      <tbody v-if="donorDetails.labs">
                                        <template v-for="(lab, index) in donorDetails.labs" :key="index">
                                          <tr v-if="lab['key'] != 'DateTime'">
                                            <td>
                                              <b>{{ lab['key'] }}</b>
                                            </td>
                                            <td>
                                              <span class="show-table-text-on-mobile">Most Recent</span>
                                              <span>{{ (lab["recent_value"] ? lab["recent_value"] : '--') }}</span>
                                              <span v-if="enableDateTime" class="show-on-mobile">
                                                {{ donorDetails.labs[index+1]["recent_value"] }}
                                              </span>
                                            </td>
                                            <td>
                                              <span class="show-table-text-on-mobile">Peak</span>
                                              <span>{{ (lab["peak_value"] ? lab["peak_value"] : '--') }}</span>
                                              <b v-if="lab['peak_values_count'] > 1" style="color:red;"> * </b>
                                              <span v-if="enableDateTime" class="show-on-mobile">
                                                {{ donorDetails.labs[index+1]["peak_value"] }}
                                              </span>
                                            </td>
                                            <td>
                                              <span class="show-table-text-on-mobile">Admit</span>
                                              <span>{{ (lab["admit_value"] ? lab["admit_value"] : '--') }}</span>
                                              <span v-if="enableDateTime" class="show-on-mobile">
                                                {{ donorDetails.labs[index+1]["admit_value"] }}
                                              </span>
                                            </td>
                                          </tr>
                                          <tr v-else-if="enableDateTime" style="background-color:#f2f2f2" class="hide-on-mobile">
                                            <td />
                                            <td><span>{{ lab["recent_value"] }}</span></td>
                                            <td><span>{{ lab["peak_value"] }}</span></td>
                                            <td><span>{{ lab["admit_value"] }}</span></td>
                                          </tr>
                                        </template>
                                      </tbody>
                                      <tbody v-else>
                                        <tr>
                                          <td colspan="4">
                                            No lab results available for this organ.
                                          </td>
                                        </tr>
                                      </tbody>
                                      <tfoot v-if="donorDetails.labs">
                                        <td colspan="4">
                                          <b style="color:red;">*</b>
                                          <b> Indicates the peak appeared in more than one measurement; the most recent is displayed</b>
                                        </td>
                                      </tfoot>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div v-if="displayAbgs" class="card">
                              <div id="abgs" class="card-header">
                                <h5 class="mb-0">
                                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseAbg" aria-expanded="false" aria-controls="collapseAbg">
                                    ABGS
                                  </button>
                                </h5>
                              </div>
                              <div id="collapseAbg" class="collapse" aria-labelledby="abgs" data-parent="#accordion">
                                <div class="card-body">
                                  <div id="tests1" class="collapse py-2">
                                    <table class="table table-bordered table-hover table-responsive table-recipient-list">
                                      <tbody v-if="donorDetails.abgs">
                                        <template v-for="(abg, index) in donorDetails.abgs" :key="index">
                                          <tr>
                                            <td style="background:#f2f2f2">
                                              <b>{{ abg["name"] }}</b>
                                            </td>
                                            <td>
                                              {{ abg["value"][0] }}
                                            </td>
                                            <td>
                                              {{ abg["value"][1] }}
                                            </td>
                                            <td>
                                              {{ abg["value"][2] }}
                                            </td>
                                          </tr>
                                        </template>
                                      </tbody>
                                      <tbody v-else>
                                        <tr>
                                          <td colspan="4">
                                            For all ABGs, FiO2 was less than 100%.
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Coordinator Notes
                      </th>
                      <td>
                        <button type="button" data-toggle="collapse" data-target="#coordinatorNotes1" 
                          aria-expanded="false" aria-controls="coordinatorNotes1" class="btn btn-link" 
                          @click="toggleCollapseText">
                          {{ collapseText }}
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td class="py-0">
                        <span class="sr-only">Coordinator notes</span>
                      </td>
                      <td class="py-0">
                        <div id="coordinatorNotes1" class="collapse py-2">
                          {{ donorDetails.coordinatorNote }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </CardSection>
          </div>
        </div>
      </div>
    </div>
    <NotesModal :match-id="selectedData.match.id" />
  </div>
</template>

<script lang="js">
import beApiClient from "@/src/be-api-client";
import { APIRoute, EP } from "~/src/endpoints";
import CardSection from "@/src/components/shared/CardSection.vue";
import { formatDateTime, formatTime, formatDate, convertCmToInch, convertKgToLbs } from "@/src/date-helpers";
import NotesModal from "@/src/components/external-clinical-area/TxCNotes.vue";
import { useAppCoreStore } from "@/src/stores/app-core";

export default {
  components: {
    CardSection,
    NotesModal
  },
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore,
    };
  },
  data() {
    return {
      records: [],
      enableDateTime: false,
      loading: true,
      majorError: null,
      donorDetails: {},
      recipient_details: null,
      collapseText: "Show",
      displayAbgs: false,
      displayLab: false,
      donor: '',
      match: '',
      matchId: '',
      organ: '',
      tc: '',
      nonRecoveries: [],
      selectedData: '',
      notes: '',
      formatDateTime: formatDateTime,
      formatTime: formatTime,
      formatDate: formatDate,
      risk_key: ['AccordingtotheOPTNpolicyineffectonthedateofreferral,doesthedonorhaveriskfactorsforblood-bornediseasetransmission', 'AccordingtotheOPTNpolicyineffectonthedateofreferraldoesthedonorhaveriskfactorsforbloodbornediseasetransmission'],
            info_keys: {blood_borne_30_days: 'Donormeetsthefollowingcriteriawithin30dayspriortoorganprocurement'},
            blood_borne_risk_factor_keys: ['Unknownmedicalorsocialhistory', 'ChildborntoamotherwithHIVHBVorHCVinfection', 'ChildbreastfedbyamotherwithHIVinfection', 'Incarcerationconfinementinjailprisonorjuvenilecorrectionfacilityfor72ormoreconsecutivehours', 'Sexwithapersonwhoinjecteddrugsfornonmedicalreasons', 'Druginjectionfornonmedicalreasons', 'Sexwithapersonwhohadsexinexchangeformoneyordrugs', 'Sexinexchangeformoneyordrugs', 'Manwhohashadsexwithanotherman', 'SexieanymethodofsexualcontactincludingvaginalanalandoralwithapersonknownorsuspectedtohaveHIVHBVorHCVinfection']
    }
  },
  mounted() {
    this.appStore.setAppBreadcrumbs([{text: 'Loading...'}]);
    this.donor = this.$route.params.donor_id;
    this.matchId = this.$route.params.id;
    this.organ = this.$route.query.organ;
    this.tc = this.$route.query.tc;
    this.getmatch();
  },
  methods: {
    toggleCollapseText(event) {
      event.currentTarget.childNodes[0].textContent = event.currentTarget.childNodes[0].textContent == 'Show' ? "Hide" : "Show";
    },
    getmatch() {
      this.loading = true;
      beApiClient.get(APIRoute(EP.matches.show, { id: this.matchId }))
      .then((responses) => {
        this.match = responses.data;
        this.getDonorDetails();
      }).catch(e => {
        this.majorError = e;
      }).finally(() => { this.loading = false });
    },
    getDonorDetails() {
      this.clearDetails();
      this.loading = true;
      Promise.all([
        beApiClient.get(APIRoute(EP.non_recoveries.index)),
        beApiClient.get(APIRoute(EP.donors.show, { id: this.donor })),
        //beApiClient.get(APIRoute(EP.coordinator_notes.index),{ params: { match_id: this.matchId }}),
      ]).then((responses) => {
        this.nonRecoveries = responses[0].data;
        //this.notes = responses[2].data;
        this.donorInfo(responses[1].data.donor, []);
      }).catch(e => {
        this.majorError = e;
      }).finally(() => {
        this.loading = false;
        this.setBreadcrumbs();
      });
    },
    setBreadcrumbs() {
      this.appStore.setAppBreadcrumbs([
        {
          to: { name: 'external-dash-available-offers' },
          text: 'Donors',
        },
        {
          text: `${this.donorDetails?.code} - ${this.match?.code} - ${this.organ}`,
        },
      ]);
    },
    donorInfo(details,c_notes) {
      const cardiac_arrest_with_downtime = details?.cause_and_times[0].cardiac_arrest_down_time ? `${details?.cause_and_times[0].cardiac_arrest_down_time} min /` : '';
      const cpr_downtime = details?.cause_and_times[0].down_time_duration ? `${details?.cause_and_times[0].down_time_duration} min` : '';
      let knownRisk = 'None';
      details?.donor_medical_histories.map(history =>{
        if (this.risk_key.includes(history.medical_condition.code)) {
          knownRisk = 'YES';
        }
      });
      const seros = details?.donor_infectious_diseases.filter((donor_disease)=> {
        return donor_disease.result == 'Positive'    
      });
      
      this.donorDetails = {
        code: details.code,
        location: details?.provider_informations[0]?.donor_hospital,
        alternative_recovery_facility: details?.provider_informations[0]?.alternative_recovery_facility,
        donorType: details?.dcd_criteria_meets ? 'DCD' : 'BD',
        age: details.age,
        gender: details.birthsex ? details.birthsex : details.gender,
        height: details.height ? (details.height + 'cm / ' + convertCmToInch(details.height) + 'in' ) : '',
        weight: details.weight ? (details.weight + 'kg / ' + convertKgToLbs(details.weight) + 'lbs' ) : '',
        bmi: details.bmi,
        kdpi: details.kdpi,
        admitDate: details.admit_date_time? formatDateTime(details.admit_date_time) : '',
        pronounced: details?.cause_and_times[0]?.death_datetime ? formatDateTime(details.cause_and_times[0].death_datetime) : '',
        bloodType: details.blood_type,
        cod: details?.cause_and_times[0].cause_of_death +',' + details?.cause_and_times[0].mechanism_of_injury,
        downtimeCpr: cardiac_arrest_with_downtime + cpr_downtime,
        donorMedicalHistories: details.donor_medical_histories,
        phmxSummary: details.medical_and_social_history_comments,
        donorHighlights: details.highlights,
        admitCourse: details.admission_course_comments,
        knownRisk: knownRisk,
        positiveSeros: seros,
        testResults: details?.ranked_test_and_diagnoses,
        coordinatorNote: this.match?.coordinator_note?.note,
        orTime: details?.or_datetime ? formatDateTime(details.or_datetime): ''  + details?.provider_informations[0].time_zone ? details.provider_informations[0].time_zone : '',
        clampTime: details?.cause_and_times[0].cross_clamp_datetime ? formatDateTime(details.cause_and_times[0].cross_clamp_datetime) : '',
        labs: this.format_labs(details?.labs_most_recent, details?.labs_peak_values,details?.labs_on_admit),
        abgs: this.format_ventilator_settings(details?.recent_ventilator_setting_with_max_fio2),
        pmhx: this.generate_pmhx(details?.donor_medical_histories),
      }
    },
    format_ventilator_settings(setting_with_max_fio2) {
      let abgKey = [];
      switch(this.organ) {
        case "Lung":
        case "Pancreas":
        case "Heart/Lung":
        case "Kidney-Pancreas":
          abgKey = [
            { name: "Date", key: "date" },
            { name: "Time", key: "time" },
            { name: "pH", key: "ph_abg" },
            { name: "PaCO2", key: "paco2" },
            { name: "PaO2", key: "pao2" },
            { name: "HCO3", key: "hco3" },
            { name: "SaO2", key: "sao2" },
            { name: "FiO2 (%)", key: "fio2" },
            { name: "RR", key: "rr_result" },
            { name: "Vt (cc)", key: "vt_cc" },
            { name: "PEEP (cmH20)", key: "peep" },
          ];
          return this.generate_setting_value(setting_with_max_fio2,abgKey);
        break;
      }
    },
    generate_setting_value(settings,abgKeys) {
      let abgs = [];
      this.displayAbgs = true;

      if(settings.length == 0) return null;

      abgKeys.forEach(abgKey => {
        let valueSet = [];
        Object.keys(settings).forEach(key => {
          if(abgKey.key == "time") {
            valueSet.push(this.formatTime(settings[key][`${abgKey.key}`]));
          } else if(abgKey.key == "date"){
            valueSet.push(this.formatDate(settings[key][`${abgKey.key}`]));
          } else {
            valueSet.push(settings[key][`${abgKey.key}`]);
          }
        });
        abgs.push({name: abgKey.name, value: valueSet})
      });
      
      return abgs;
    },
    format_labs(labs_most_recent, labs_peak_values, labs_admit_values) {
      let details = [];
      let labs = [];

      switch(this.organ) {

        case "Liver":
          labs = [
              { name: "Na(mEqL)", key: "na", lab_type: "lab_panel" },
              { name: "Glucose(mg/dL)", key: "glucose", lab_type: "lab_panel"  },
              { name: "Creatinine(mg/dL)", key: "serum_creatinine", lab_type: "lab_panel" },
              { name: "Total Bilirubin(mg/dL)", key: "total_bilirubin", lab_type: "lab_panel" },
              { name: "Direct Bilirubin(mg/dL)", key: "direct_bilirubin", lab_type: "lab_panel" },
              { name: "Indirect Bilirubin(mg/dL)", key: "indirect_bilirubin", lab_type: "lab_panel" },
              { name: "SGOT(AST) (u/L)", key: "sgot_ast", lab_type: "lab_panel" },
              { name: "SGPT(ALT) (u/L)", key: "sgpt_alt", lab_type: "lab_panel" },
              { name: "Alkaline phosphatase(u/L)", key: "alkaline_phosphatase", lab_type: "lab_panel" },
              { name: "GGT(u/L)", key: "ggt", lab_type: "lab_panel" },
              { name: "Albumin(g/dL)", key: "albumin", lab_type: "lab_panel" },
              { name: "Total Protein(g/dL)", key: "total_protein", lab_type: "lab_panel" },
              { name: "Prothrombin PT", key: "prothrombin_pt", lab_type: "lab_panel" },
              { name: "INR", key: "inr", lab_type: "lab_panel" },
              { name: "PTT", key: "ptt", lab_type: "lab_panel" },
              { name: "HbA1c", key: "hba1c", lab_type: "lab_extra_value" },
          ];
          details = this.generate_lab_array(labs, labs_most_recent, labs_peak_values, labs_admit_values);
          return details;
        break;

        case "Kidney":
            labs = [
                { name: "BUN(mg/dL)", key: "bun", lab_type: "lab_panel" },
                { name: "Glucose(mg/dL)", key: "glucose", lab_type: "lab_panel" },
                { name: "Creatinine(mg/dL)", key: "serum_creatinine", lab_type: "lab_panel" },
                { name: "Prothrombin PT", key: "prothrombin_pt", lab_type: "lab_panel" },
                { name: "INR", key: "inr", lab_type: "lab_panel" },
                { name: "PTT", key: "ptt", lab_type: "lab_panel" },
                { name: "HbA1c", key: "hba1c", lab_type: "lab_extra_value" },
                
            ];
          details = this.generate_lab_array(labs, labs_most_recent, labs_peak_values, labs_admit_values);
            return details;
        break;

        case "Pancreas":
            labs = [
                { name: "Na(mEqL)", key: "na", lab_type: "lab_panel" },
                { name: "Glucose(mg/dL)", key: "glucose", lab_type: "lab_panel"  },
                { name: "Serum Amylase (u/L)", key: "serum_amylase", lab_type: "lab_panel"  },
                { name: "Serum Lipase (u/L)", key: "serum_lipase", lab_type: "lab_panel"  },
                { name: "Serum Lipase Upper Normal Limit (u/L)", key: "serum_lipase_upper_normal_limit", lab_type: "lab_panel"  },
                { name: "Prothrombin PT", key: "prothrombin_pt", lab_type: "lab_panel"  },
                { name: "INR", key: "inr", lab_type: "lab_panel" },
                { name: "PTT", key: "ptt", lab_type: "lab_panel" },
                { name: "HbA1c", key: "hba1c", lab_type: "lab_extra_value" },
            ];
          details = this.generate_lab_array(labs, labs_most_recent, labs_peak_values, labs_admit_values);
            return details;
        break;

        case "Kidney-Pancreas":
            labs = [
                { name: "Na(mEqL)", key: "na", lab_type: "lab_panel" },
                { name: "BUN(mg/dL)", key: "bun", lab_type: "lab_panel" },
                { name: "Glucose(mg/dL)", key: "glucose", lab_type: "lab_panel"  },
                { name: "Creatinine(mg/dL)", key: "serum_creatinine", lab_type: "lab_panel" },
                { name: "Serum Amylase (u/L)", key: "serum_amylase", lab_type: "lab_panel"  },
                { name: "Serum Lipase (u/L)", key: "serum_lipase", lab_type: "lab_panel"  },
                { name: "Serum Lipase Upper Normal Limit (u/L)", key: "serum_lipase_upper_normal_limit", lab_type: "lab_panel"  },
                { name: "Prothrombin PT", key: "prothrombin_pt", lab_type: "lab_panel"  },
                { name: "INR", key: "inr", lab_type: "lab_panel" },
                { name: "PTT", key: "ptt", lab_type: "lab_panel" },
                { name: "HbA1c", key: "hba1c", lab_type: "lab_extra_value" },
            ];
          details = this.generate_lab_array(labs, labs_most_recent, labs_peak_values, labs_admit_values);
            return details;
        break;

        case "Intestine":
            labs = [ 
                { name: "Na(mEqL)", key: "na", lab_type: "lab_panel" },
                { name: "Glucose(mg/dL)", key: "glucose", lab_type: "lab_panel"  },
                { name: "Creatinine(mg/dL)", key: "serum_creatinine", lab_type: "lab_panel" },
                { name: "Total Bilirubin(mg/dL)", key: "total_bilirubin", lab_type: "lab_panel" },
                { name: "Direct Bilirubin(mg/dL)", key: "direct_bilirubin", lab_type: "lab_panel" },
                { name: "Indirect Bilirubin(mg/dL)", key: "indirect_bilirubin", lab_type: "lab_panel" },
                { name: "SGOT(AST) (u/L)", key: "sgot_ast", lab_type: "lab_panel" },
                { name: "SGPT(ALT) (u/L)", key: "sgpt_alt", lab_type: "lab_panel" },
                { name: "Alkaline phosphatase(u/L)", key: "alkaline_phosphatase", lab_type: "lab_panel" },
                { name: "Prothrombin PT", key: "prothrombin_pt", lab_type: "lab_panel" },
                { name: "INR", key: "inr", lab_type: "lab_panel" },
                { name: "PTT", key: "ptt", lab_type: "lab_panel" },
                { name: "Serum Amylase (u/L)", key: "serum_amylase", lab_type: "lab_panel"  },
                { name: "Serum Lipase (u/L)", key: "serum_lipase", lab_type: "lab_panel"  },
                { name: "HbA1c", key: "hba1c", lab_type: "lab_extra_value" },
            ];
          details = this.generate_lab_array(labs, labs_most_recent, labs_peak_values, labs_admit_values);
            return details;
        break;

        case "Heart":
        case "Heart/Lung":
            labs = [
                { name: "Troponin I(ng/mL)", key: "troponin_i", lab_type: "lab_value" },
                { name: "Troponin T(ng/mL)", key: "troponin_t", lab_type: "lab_value" },
                { name: "CPK(u/L)", key: "cpk", lab_type: "lab_value" },
                { name: "CKMB(ng/mL)", key: "ckmb", lab_type: "lab_value" }
            ];
          details = this.generate_lab_array(labs, labs_most_recent, labs_peak_values, labs_admit_values);
            return details;
        break;

      }

      return null;
    },
    generate_lab_array(data, currentLabs, peakLabs, admitLabs) {
      let lab_array = [];
      this.displayLab = true;
      data.forEach(lab => {
        const lab_type = lab['lab_type'];
        const current = currentLabs[`current_${lab_type}`];
        const peak = peakLabs[`peak_${lab_type}`];
        const admit = admitLabs[`admit_${lab_type}`];

        lab_array.push({
          key: lab['name'],
          recent_value: current[lab['key']] ? current[lab['key']][lab['key']] : '--',
          peak_value: peak[lab['key']][0] ? peak[lab['key']][0][lab['key']] : '--', 
          admit_value: admit[lab['key']] ? admit[lab['key']][lab['key']] : '--',
          peak_values_count: peak[lab['key']]?.length
        });

        if(lab_type == "lab_extra_value") {
            lab_array.push({
            key: 'DateTime',
            recent_value: current[lab['key']] ?  this.formatDateTime(current[lab['key']]['hba1c_datetime']) : null,
            peak_value: peak[lab['key']][0] ? this.formatDateTime(current[lab['key']]['hba1c_datetime']) : null,
            admit_value: admit[lab['key']] ? this.formatDateTime(current[lab['key']]['hba1c_datetime']) : null,
          });

        } else {
          lab_array.push({
            key: 'DateTime',
            recent_value: current[lab['key']] ? this.formatDate(current[lab['key']]['date']) + ' ' + this.formatTime(current[lab['key']]['time']) : null,
            peak_value: peak[lab['key']][0] ? this.formatDate(peak[lab['key']][0]['date']) + ' ' + this.formatTime(peak[lab['key']][0]['time']) : null,
            admit_value: admit[lab['key']] ? this.formatDate(admit[lab['key']]['date']) + ' ' + this.formatTime(admit[lab['key']]['time']) : null,
          });
        }
       
      });
      return lab_array;
    },
    generate_pmhx(donor_medical_histories) {
      let pmhx = {
        normal: [],
        blood_borne: [],
        known_risk: 'None',
        criteria_thirty_days: ''
      };

      let normal_pmhx = donor_medical_histories.filter(history => {
          return !this.risk_key.includes(history.medical_condition.code) 
              && !this.blood_borne_risk_factor_keys.includes(history.medical_condition.code)
              && !this.info_keys.blood_borne_30_days.includes(history.medical_condition.code)
              && (history.info ? (history.info.trim() !== 'N/A') : true);
      });
      let blood_borne_pmhx = donor_medical_histories.filter(history => {
          return this.blood_borne_risk_factor_keys.includes(history.medical_condition.code)
              && (history.info ? (history.info.trim() !== 'N/A') : true)
          ;
      });
      let known_risk = donor_medical_histories.find(history => {
          return this.risk_key.includes(history.medical_condition.code);
      }) ? 'YES' : 'None';

      if (normal_pmhx.length > 0) {
        pmhx.normal = this.formatPMHx(normal_pmhx);
      }
      if (blood_borne_pmhx.length > 0) {
        pmhx.blood_borne = this.formatPMHx(blood_borne_pmhx);
        pmhx.criteria_thirty_days = donor_medical_histories.find(history => {
            return this.info_keys.blood_borne_30_days.includes(history.medical_condition.code);
        }) ? '(meets criteria within 30 days)' : '';
      }
      pmhx.known_risk = known_risk;

      return pmhx;
    },
    formatPMHx(donor_medical_histories) {
        let pmhx_array = [];
        donor_medical_histories.map(history =>{
            if(history.info && (history.info.trim() == 'UNKNOWN') ) {
              pmhx_array.push({
                name: history.medical_condition.name || '<unknown condition name>',
                info: history.info.trim()
              });
            } else if (history.info && (history.info.trim() == 'N/A') ) {
                // don't add
            } 
            else {
              pmhx_array.push({
                name: history.medical_condition.name || '<unknown condition name>',
                info: history.info ? `YES, ${history.info.trim()}` : "YES"
              });
            }
        });
        
        return pmhx_array;
    },         
    canEnableDateTime(event) {
      this.enableDateTime = !this.enableDateTime;
      event.currentTarget.childNodes[0].textContent = event.currentTarget.childNodes[0].textContent == 'Show DateTime' ? "Hide DateTime" : "Show DateTime";
    },
    clearDetails() {
      this.donorDetails = null;
      this.recipient_details = null;
    },
    getModalData() {
      this.selectedData = {
        donor: this.donor,
        match: this.match,
        organ: this.organ,
        tc: this.tc,
        notes: this.notes
      }
    },
  }
}
</script>
