<template>
  <LeftNav :enabled-nav="enabledAdminNavs" />
</template>

<script lang="js">
import {useAppCoreStore} from "@/src/stores/app-core";
import {PermissionNames} from "@/src/enums";
import LeftNav from "@/src/components/shared/LeftNav.vue";

/**
 * This array controls the items that appear in the left nav.
 * Array contains Objects for each section. 
 * {
 *    name: 'Title of the Section', // required
 *    subsections: [ // required. The actual Links to display in the subsection. Each link is an object
 *      {
 *        name: 'Text of the link', // required.
 *        url_path: 'string_name_of_the_vue_router_path', // required.
 *        permission_key: PermissionNames.XXX_YYYY // required. The permission to check if the user has it or not, use PermissionNames enum
 *        check_feature: (settings) > { return BOOLEAN BASED ON FEATURE CHECK } // Optional function to check if a feature should be checked before enabling
 *                                                                              // This link. appStore.applicationSettings are passed to the function.  
 *      }
 *    ]
 *  
 * }
 */
const AdminAreaNavs = [
  {
    name: 'User, Teams and Roles',
    sub_sections: [
      {
        name: 'Manage Membership Categories',
        url_path: 'membership_categories',
        permission_key: PermissionNames.ADMIN_MEMBERSHIP_CATEGORIES,
      },
      {
        name: 'Manage Teams',
        url_path: 'admin_teams',
        permission_key: PermissionNames.ADMIN_TEAMS,
      },
      {
        name: 'Manage Roles',
        url_path: 'admin_roles',
        permission_key: PermissionNames.ADMIN_ROLES,
      },
      {
        name: 'Manage Users',
        url_path: 'admin_users',
        permission_key: PermissionNames.ADMIN_USERS,
      },
      {
        name: 'Import Users',
        url_path: 'admin_mass_import_users',
        permission_key: PermissionNames.ADMIN_USERS_MASS_IMPORT,
      }
      
    ],
  },
  {
    name: 'Comms Templates',
    sub_sections: [
      {
        name: 'Manage Template Categories',
        url_path: 'admin_communication_template_categories',
        permission_key: PermissionNames.ADMIN_COMMUNICATION_TEMPLATES,
      },
      {
        name: 'Manage Templates',
        url_path: 'admin_communication_templates',
        permission_key: PermissionNames.ADMIN_COMMUNICATION_TEMPLATES,
      },
    ],
  },
  {
    name: 'Auto rule outs',
    sub_sections: [
      {
        name: 'Manage Auto rule outs',
        url_path: 'admin_aro_rules',
        permission_key: PermissionNames.ADMIN_ARO_RULES,
      },
      {
        name: 'Manage Procedures',
        url_path: 'admin_procedure_notes',
        permission_key: PermissionNames.ADMIN_PROCEDURE_NOTES,
      }
    ],
  },
  {
    name: 'Troubleshoot',
    sub_sections: [
      {
        name: 'Donors',
        url_path: 'admin_troubleshoot_donors',
        permission_key: PermissionNames.ADMIN_TROUBLESHOOT_DONORS,
      }
    ],
  },
  {
    name: 'Integrations',
    sub_sections: [
      {
        name: 'OmniLife Workflow Mappings',
        url_path: 'admin_integrations_omnilife_workflow_mappings',
        permission_key: PermissionNames.ADMIN_INTEGRATIONS_OMNILIFE_WORKFLOWS,
        check_feature: (settings) => { return settings && settings.integrations && settings.integrations.omnilife_workflows }
      },
      {
        name: 'OmniLife User Mappings',
        url_path: 'admin_integrations_omnilife_user_mappings',
        permission_key: PermissionNames.ADMIN_INTEGRATIONS_OMNILIFE_WORKFLOWS,
        check_feature: (settings) => { return settings && settings.integrations && settings.integrations.omnilife_workflows }
      }
    ],
  },
  {
    name: 'Application Settings',
    sub_sections: [
      {
        name: 'Manage Robocall Reduction',
        url_path: 'admin_configurations_robocall_reduction',
        permission_key: PermissionNames.ADMIN_CONFIGURATIONS_ROBOCALL_REDUCTION,
      },
    ]
  }
]
export default {
  components: {
    LeftNav
  },
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore
    }
  },
  computed: {
    user() {
      return this.appStore.currentUser;
    },
    userPermissions() {
      return this.appStore.currentUser ? this.appStore.currentUser.userPermissions : null;
    },
    enabledAdminNavs() {
      if(!this.user || !this.userPermissions) return [];
       return AdminAreaNavs.map((admin_nav) => {
        admin_nav.sub_sections.map((sub) =>{
          const nav_perm = this.userPermissions[sub.permission_key];
          sub.enabled = nav_perm ? (nav_perm.read || false) : false;
          if(sub.check_feature) {
            // Have permission, but feature disabled, then it will be disabled.
            sub.enabled = sub.check_feature(this.appStore.applicationSettings) && sub.enabled ? true : false;
          }
          return sub;
        })
        admin_nav.enabled = admin_nav.sub_sections.some(sub => sub.enabled);
        return admin_nav;
      })
    },
  }
}
</script>
